import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Warehouse from "@/views/Warehouse";
import Tag from "@/views/Tag";
import OpenSource from "@/views/OpenSource";
import Search from "@/views/Search";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: "主页",
            path: "/"
        },
        children: [
            {
                path: ":current/:pageSize",
                // component: SearchResult,
                meta: {
                    title: "主页",
                    path: "/"
                },
                children: [
                    {
                        path: "id/:id",
                        // component: SearchResult,
                        meta: {
                            title: "此仓库中的配件",
                            path: "/warehouse"
                        }
                    },
                    {
                        path: "tag/:tagId",
                        meta: {
                            title: "此标签中的配件",
                            path: "/warehouse"
                        }
                    },
                    {
                        path: "name/:name",
                        meta: {
                            title: "搜索结果",
                            path: "/warehouse"
                        }
                    }
                ]
            }
        ]

    }, {
        path: '/warehouse',
        name: '仓库',
        component: Warehouse,
        meta: {
            title: "仓库",
            path: "/warehouse"
        },
        children: [
            {
                path: ":current/:pageSize",
                // component: SearchResult,
                meta: {
                    title: "仓库",
                    path: "/warehouse"
                }
            }
        ]

    }, {
        path: '/tag',
        name: '标签',
        component: Tag,
        meta: {
            title: "标签",
            path: "/tag"
        },
        children: [
            {
                path: ":current/:pageSize",
                // component: SearchResult,
                meta: {
                    title: "标签",
                    path: "/tag"
                }
            }
        ]

    },
    {
        path: "/search",
        component: Search,
        meta: {
            title: "搜索",
            path: "/search"
        }
    }, {
        path: "/opensource",
        component: OpenSource,
        meta: {
            title: "开放源代码许可",
            path: "/opensource"
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            title: "关于"
        }
    }
]

const router = new VueRouter({
    mode: "history",
    routes
})

export default router
