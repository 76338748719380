<template>
    <div style="text-align: center;margin-top: 40px">
        <a-icon type="search" style="font-size: 60px; margin: 30px"/>

        <div class="search-bar">
            <a-input-search size="large" placeholder="输入配件名称的关键词" enter-button @search="onSearch" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Search",
    methods: {
        onSearch(v) {
            if (v.trim().length === 0) {
                this.$message.error("搜索内容不能为空");
            } else {
                this.$router.push(`/1/10/name/${v}`);
            }
        }
    }
}
</script>

<style scoped>
.search-bar {
    /*display: inline-block;*/
    width: 80% !important;
    margin: 30px auto auto;
}
</style>