<template>
    <div class="home">
<!--        {{ imageUrl }}-->
<!--        {{item}}-->




        <!--        {{tagList}}-->
        <!--        {{selectedList}}-->
        <a-spin class="loading" :spinning="spinning" tip="加载中...">
            <div v-if="spinning" class="hold">
                <br>
            </div>
            <a-list v-if="!spinning" item-layout="vertical" size="large" :pagination="false"
                    :data-source="list.result.list">
                <a-list-item slot="renderItem" key="item.title" slot-scope="item, index">
                    <!--                    <a-tooltip placement="bottom" slot="actions">-->
                    <!--                        <template slot="title">-->
                    <!--                            <span>所属仓库</span>-->
                    <!--                        </template>-->
                    <!--                        <span>-->
                    <!--                            <a-icon type="home" style="margin-right: 8px"/>-->
                    <!--                            仓库1-->
                    <!--                        </span>-->
                    <!--                    </a-tooltip>-->


                    <!--                    <a-tooltip placement="bottom" slot="actions">-->
                    <!--                        <template slot="title">-->
                    <!--                            <span>查看详情</span>-->
                    <!--                        </template>-->
                    <!--                        <a @click="detail(item)">详情</a>-->
                    <!--                    </a-tooltip>-->


                    <img
                        slot="extra"
                        width="200"
                        alt="logo"
                        :src="item.imageUrl"
                    />
                    <!--                    {{item}}-->

                    <a-list-item-meta :description="item.introduce">
                        <a slot="title" @click="detail(item)">{{ item.name }}</a>
                    </a-list-item-meta>
                    <!--                {{ item.introduce}}-->

                    <p>入库日期：{{ item.dateOfManufacture }}</p>
                    <p>库存：{{ item.count }} 个</p>
                    <p>单价：{{ item.price.toFixed(2) }} 元</p>
                    <p>总价值：{{ (item.count * item.price).toFixed(2) }} 元</p>
                </a-list-item>
                <a-pagination
                    style="margin-top: 10px;text-align: right"
                    show-size-changer
                    :default-current="1"
                    :total="list.result.total"
                    :pageSize="pageSize"
                    v-model="current"
                    @showSizeChange="onShowSizeChange"
                />
            </a-list>
        </a-spin>


        <a-drawer
            title="配件详情"
            placement="right"
            :closable="true"
            :visible="visible"
            width="400"
            @close="visible=false"
        >
            <a-button icon="edit" style="margin-right: 10px" @click="childrenDrawer=true">
                修改
            </a-button>
            <a-button type="danger" icon="delete" @click="deleteDialog = true">
                删除
            </a-button>
            <!--            {{ display }}-->

            <div style="text-align: center">
                <img
                    width="200"
                    alt="logo"
                    :src="display.imageUrl"
                    style="margin: 20px"
                />
            </div>


            <p>id：{{ display.id }}</p>
            <p>名称：{{ display.name }}</p>
            <p>介绍：{{ display.introduce }}</p>
            <p>数量：{{ display.count }} 个</p>
            <p>单价：{{ display.price.toFixed(2) }} 元</p>
            <p>添加时间：{{ display.dateOfManufacture }}</p>
            <p>所属仓库：{{ warehouse.result.name }}</p>
            <p>标签：</p>
            <a-list size="small" bordered :data-source="tagList">
                <a-list-item slot="renderItem" slot-scope="item, index">
                    <!--                    {{ item }}-->
                    <a-tag color="pink" @click="$router.push(`/1/10/tag/${item.id}`);$router.go(0)">
                        {{ item.name }}
                    </a-tag>
                </a-list-item>
            </a-list>


            <a-drawer
                title="修改配件信息"
                width="400"
                :closable="true"
                :visible="childrenDrawer"
                @close="childrenDrawer = false"
            >
                <a-button icon="close" @click="childrenDrawer = false">
                    关闭
                </a-button>

                <a-button style="margin-left: 10px" icon="check" key="submit" type="danger" :loading="ok"
                          @click="modify">
                    提交
                </a-button>
                <!--                {{childrenDrawer + "-&#45;&#45;"}}-->



                <a-form-item style="margin-top: 10px" label="名称：">
                    <a-input v-model="submitData.name"/>
                </a-form-item>
                <a-form-item label="图片：">
                    <div>
                        <a-upload
                            name="avatar"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            :name="'image'"
                            :headers="headers"
                            action="https://image.integer.top/api/upload"
                            :before-upload="beforeUpload"
                            @change="handleChange"
                        >
                            <img v-if="imageInfo"  width="100%" :src="imageInfo" alt="avatar"/>
                            <div v-else>
                                <a-icon :type="loadingImage ? 'loading' : 'plus'"/>
                                <div class="ant-upload-text">
                                    上传图片
                                </div>
                            </div>
                        </a-upload>
                    </div>
                </a-form-item>

                <a-form-item label="图片链接：">
                    <a-input disabled="true" v-model="submitData.imageUrl"/>
                </a-form-item>
                <a-form-item label="介绍：">
                    <a-input v-model="submitData.introduce"/>
                </a-form-item>
                <a-form-item label="数量：">
                    <a-input v-model="submitData.count"/>
                </a-form-item>
                <a-form-item label="单价：">
                    <a-input v-model="submitData.price"/>
                </a-form-item>
                <a-form-item label="所属仓库：">
                    <a-select v-model="warehouse.result.id"
                              style="width: 200px"
                              @change="warehouseChange">
                        <a-select-option
                            v-for="i in warehouseList"
                            :key="i.id">
                            {{ i.name + "-id:" + i.id }}
                        </a-select-option>
                    </a-select>
                    <!--                    <a-input v-model="submitData.warehouseId"/>-->
                </a-form-item>


<!--                <a-form>-->
<!--                    <a-form-item label="生产日期：(年-月-日的格式)">-->
<!--                        <a-input v-model="submitData.dateOfManufacture"/>-->
<!--                    </a-form-item>-->
<!--                </a-form>-->
                <a-form>
                    <a-form-item label="标签">
                        <a-select
                            mode="multiple"
                            placeholder="选择标签"
                            v-model="selectedList"
                            @change="tagChange"
                        >
                            <a-select-option v-for="i in allTags" :key="i.id">
                                {{ i.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </a-drawer>
        </a-drawer>

        <a-modal v-model="deleteDialog" title="提示">
            <p> 您确定要删除<span style="font-weight: bold">{{ display.name }}</span>？</p>
            <p>该操作不可逆。</p>
            <a-button slot="footer" @click="deleteDialog = false">
                取消
            </a-button>
            <a-button key="submit" slot="footer" type="danger" :loading="ok" @click="handleOk">
                确定
            </a-button>
        </a-modal>

        <!--                {{ list }}-->
    </div>
</template>

<script>
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export default {
    name: 'Home',
    components: {},
    data() {
        return {
            list: null,
            current: 1,
            pageSize: 10,
            spinning: true,
            visible: false,
            display: {
                "id": null,
                "warehouseId": 1,
                "name": "机箱",
                "count": 15,
                "introduce": "这是机箱的介绍",
                "price": 33.33,
                "dateOfManufacture": "",
                "imageUrl": "https://api.daidr.me/apis/imgholder/800x800.jpg?bg=409EFF&fg=ffffff&text=机箱"
            },
            deleteDialog: false,
            ok: false,
            childrenDrawer: false,
            date: null,
            msg: null,
            warehouse: {
                "code": 200,
                "status": "SUCCEED",
                "result": {
                    "id": 1,
                    "name": "默认仓库",
                    "introduce": "这是一个默认的仓库",
                    "imageUrl": "https://api.daidr.me/apis/imgholder/800x800.jpg?bg=F56C6C&fg=ffffff&text=%E4%BB%93%E5%BA%93"
                }
            },
            submitData: {
                "code": 200,
                "status": "SUCCEED",
                "result": {
                    "id": 1,
                    "name": "默认仓库",
                    "introduce": "这是一个默认的仓库",
                    "imageUrl": "https://api.daidr.me/apis/imgholder/800x800.jpg?bg=F56C6C&fg=ffffff&text=%E4%BB%93%E5%BA%93"
                }
            },
            id: null,
            warehouseList: [
                {
                    "id": 123455,
                    "name": "仓库",
                    "introduce": "介绍",
                    "imageUrl": "url"
                }
            ],
            tagList: [
                {
                    "id": 1,
                    "name": "USB",
                    "introduce": null
                }],
            allTags: [
                {
                    "id": 1,
                    "name": "USB",
                    "introduce": "这是USB的一个标签"
                }
            ],
            selectedList: [],
            tagId: null,
            searchName: null,
            loadingImage: false,
            imageUrl: null,
            headers: {
                token: '54d9250182bff3f45709132d86795dc4',
            },
            imageInfo: ''
        }
    }
    ,
    created() {
        // this.getTagName(1);
        // console.log(this.$route.params.tagId)
        if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
            this.id = this.$route.params.id;
        } else {
            if (this.$route.params.current !== undefined && this.$route.params.current !== null && this.$route.params.pageSize !== undefined && this.$route.params.pageSize !== null) {
                this.current = Number.parseInt(this.$route.params.current);
                this.pageSize = Number.parseInt(this.$route.params.pageSize);
                // this.current = this.$route.params.current;
                // this.pageSize = this.$route.params.pageSize;
                this.axios.get("/warehouse/1/2147483647")
                    .then(res => this.warehouseList = res.data.result.list);
                this.axios.get("/tag/1/2147483647")
                    .then(res => this.allTags = res.data.result.list);
            } else {
                this.$router.push("/1/10");
            }
        }

        this.load();
        // // console.log(this.axios)
        // this.axios.get("/part/1/1").then(res => this.list = res.data);
        // // console.log(this.list)
    }
    ,
    methods: {
        onShowSizeChange(current, pageSize) {
            this.pageSize = pageSize;
            this.load();
        }
        ,
        load() {
            this.spinning = true;
            this.$router.push({
                params: {
                    current: this.current.toString(),
                    pageSize: this.pageSize.toString()
                    // current: this.current,
                    // pageSize: this.pageSize
                }
            });

            if (this.$route.params.tagId !== undefined && this.$route.params.tagId !== null) {
                this.tagId = this.$route.params.tagId;
                this.axios.get(`/part/${this.current}/${this.pageSize}/tag/${this.$route.params.tagId}`).then(res => {
                    this.list = res.data;
                    if (this.list.result.pages < Number.parseInt(this.current) && this.list.result.total !== 0) {
                        this.$router.push(`/${this.list.result.pages}/${this.pageSize}/tag/${this.tagId}`);
                        this.$router.go(0);
                    } else {
                        setTimeout(() => this.spinning = false, 955);
                    }
                });
            } else if (this.$route.params.name !== undefined && this.$route.params.name !== null) {
                this.searchName = this.$route.params.name;
                this.axios.get(`/part/${this.current}/${this.pageSize}/search/${this.searchName}`).then(res => {
                    this.list = res.data;
                    if (this.list.result.pages < Number.parseInt(this.current) && this.list.result.total !== 0) {
                        this.$router.push(`/${this.list.result.pages}/${this.pageSize}/name/${this.searchName}`);
                        this.$router.go(0);
                    } else {
                        setTimeout(() => this.spinning = false, 955);
                    }
                });
            } else {
                this.axios.get(`/part/${this.current}/${this.pageSize}` + (this.id === null ? "" : `/${this.id}`)).then(res => {
                    this.list = res.data;
                    if (this.list.result.pages < Number.parseInt(this.current) && this.list.result.total !== 0) {
                        this.$router.push(`/${this.list.result.pages}/${this.pageSize}`);
                        this.$router.go(0);
                    } else {
                        setTimeout(() => this.spinning = false, 955);
                    }
                });
            }


        }
        ,
        loadPart() {
        }
        ,
        handleOk() {
            this.ok = true;
            this.axios.post(`/part/${this.display.id}`).then(res => {
                let result = res.data;
                if (result.code === 400) {
                    setTimeout(() => {
                        this.$message.error(result.result);
                        this.ok = false;
                        this.deleteDialog = false;
                        this.childrenDrawer = false;
                        setTimeout(() => this.$router.go(0), 955);
                    }, 955);
                } else {
                    setTimeout(() => {
                        this.$message.success(result.result);
                        this.ok = false;
                        this.deleteDialog = false;
                        setTimeout(() => this.$router.go(0), 955);
                    }, 955);
                }
            })
        }
        ,
        modify() {
            if (this.submitData.name.length === 0) {
                this.$message.error("配件名称不能为空")
            } else if (this.submitData.name.length > 50) {
                this.$message.error("配件名称长度不能大于50");
            } else if (this.submitData.introduce.length === 0) {
                this.$message.error("配件的介绍不能为空")
            } else if (this.submitData.introduce.length > 200) {
                this.$message.error("配件的介绍不能大于200")
            } else if (this.submitData.count.length === 0) {
                this.$message.error("配件的数量不能为空")
            } else if (Number.parseInt(this.submitData.count) < 0) {
                this.$message.error("配件的数量不能小于0")
            } else if (isNaN(Number.parseInt(this.submitData.count))) {
                this.$message.error("请输入正确的数据")
            } else if (Number.parseInt(this.submitData.count) > 2147483647) {
                this.$message.error("配件的数量不能大于2147483647")
            } else if (this.submitData.price.length === 0) {
                this.$message.error("配件的价格不能为空")
            } else if (Number.parseFloat(this.submitData.price) < 0) {
                this.$message.error("配件的价格不能小于0")
            } else if (this.submitData.warehouseId.length === 0) {
                this.$message.error("配件的仓库不能为空")
            } else if (this.submitData.imageUrl.length === 0) {
                this.$message.error("配件的图片链接不能为空")
            } else {
                this.ok = true;
                this.axios.post("/part/update", this.submitData)
                    .then(res => {
                        let result = res.data;
                        if (result.code === 400) {
                            setTimeout(() => {
                                this.$message.error(result.result);
                                this.ok = false;
                            }, 955);
                        } else {
                            setTimeout(() => {
                                this.$message.success("修改成功");
                                this.ok = false;
                                setTimeout(() => this.$router.go(0), 955);
                            }, 955);
                        }
                    })
            }
        },
        detail(item) {
            this.display = item;
            this.imageInfo = item.imageUrl;
            this.submitData = JSON.parse(JSON.stringify(item));
            this.visible = true;
            this.axios.get(`/warehouse/${this.display.warehouseId}`).then(res => this.warehouse = res.data);
            this.axios.get(`/tag/name/${this.display.id}`).then(res => {
                this.tagList = res.data.result
                this.selectedList = [];
                this.tagList.forEach(item => this.selectedList.push(item.id));
            });

        },
        warehouseChange(v) {
            // // console.log(v)
            this.submitData.warehouseId = v;
        },
        tagChange(v) {
            // console.log(v);
            this.submitData.tags = v;
        },
        handleChange(info) {
            if (info.file.status === 'uploading') {
                this.loading = true;
                return;
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.

                // // console.log(info.file.response);
                this.submitData.imageUrl = this.imageUrl = info.file.response.data.url;

                getBase64(info.file.originFileObj, imageUrl => {
                    setTimeout(() => {
                        this.imageInfo = imageUrl;
                        this.loading = false;
                        this.$message.success("上传成功")
                    }, 1500)
                });
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('只能上传JPG或者PNG后缀的图片');
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('图片必须小于2MB');
            }
            return isJpgOrPng && isLt2M;
        }
    }
    ,
    watch: {
        current() {
            this.load();
        }
        ,
        // date(_new, _old) {
        //     // this.display.dateOfManufacture = this.date.getYear() + "-" + this.date.getMonth() + this.date.getDay();
        //     // console.log(_new.getYear(),"---", _old);
        // }
    }
    ,
}
</script>
<style scoped>
.hold {
    line-height: 10;
}
</style>