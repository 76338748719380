<template>
    <div>
        <p>本项目的诞生离不开一些开源组织和开源项目所提供的帮助，在此向开源致敬！</p>
        <a-list item-layout="horizontal" :data-source="info">
            <a-list-item slot="renderItem" slot-scope="item, index">
                <a slot="actions" @click="showLicense(item.content)">
                    Show License
                </a>
                <a slot="actions" target="_blank" :href="item.url">
                    Homepage
                </a>
                <a-list-item-meta
                    :description="'License:' + item.license"
                >
                    <a slot="title">{{ item.name }}</a>
                </a-list-item-meta>
            </a-list-item>
        </a-list>



        <a-drawer
            title="License"
            placement="right"
            :closable="true"
            :visible="visible"
            width="400"
            @close="visible = false"
        >
            <p class="text-wrapper">
                {{this.license}}
            </p>
        </a-drawer>
    </div>
</template>

<script>
import {info} from "@/openSource.json";

export default {
    name: "OpenSource",
    data() {
        return {
            info,
            visible: false,
            license: null
        }
    },
    methods: {
        showLicense(content) {
            this.license = content;
            this.visible = true;
            console.log(content);
        }
    }
}
</script>

<style scoped>
.text-wrapper {
    white-space: pre-wrap;
}
</style>