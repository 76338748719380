<template>
    <div>
        <div class="logo" style="font-weight: bold; font-size: 18px" v-if="!isMobile">
            <div class="name">
                欢迎
            </div>
        </div>
        <a-menu theme="light" :default-selected-keys="select" mode="inline">
            <a-menu-item key="1" @click="$router.push('/'); title = '全部配件'; $router.go(0)">
                <a-icon type="unordered-list"/>
                <span>全部配件</span>
            </a-menu-item>
            <a-menu-item key="2" @click="$router.push('/warehouse'); title = '仓库'; $router.go(0)">
                <a-icon type="inbox"/>
                <span>仓库</span>
            </a-menu-item>
            <a-menu-item key="3" @click="$router.push('/search/'); title = '搜索';">
                <a-icon type="search"/>
                <span>搜索</span>
            </a-menu-item>
            <a-menu-item key="4" @click="$router.push('/tag'); title = '标签';">
                <a-icon type="file-text"/>
                <span>标签</span>
            </a-menu-item>
            <a-menu-item key="5" @click="$router.push('/opensource'); title = '开放源代码许可';">
                <a-icon type="file-protect"/>
                <span>开放源代码许可</span>
            </a-menu-item>
        </a-menu>
    </div>
</template>

<script>
export default {
    name: "Menu",
    data() {
        return {
            select: ['1']
        }
    },
    created() {
        this.select = this.menu.get(this.$route.meta.path).select;
    }
}
</script>

<style scoped>
.logo {
    margin: 0 !important;
    /*background-color: #000000;*/
    border-right: #e8e8e8 1px solid;
    height: 64px !important;
}

.name {
    padding-top: 20px;
}

</style>