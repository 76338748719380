<template>
    <div class="home">
<!--        <a-button @click="$router.go(0)">aaa</a-button>-->
<!--        {{ list }}-->
        <a-spin class="loading" :spinning="spinning" tip="加载中...">
            <div v-if="spinning" class="hold">
                <br>
            </div>
            <a-list v-if="!spinning" item-layout="vertical" size="small" :pagination="false"
                    :data-source="list.result.list">
                <a-list-item slot="renderItem" key="item.title" slot-scope="item, index">

                    <a-list-item-meta :description="item.introduce">
                        <a slot="title" @click="detail(item)">{{ item.name }}</a>
                    </a-list-item-meta>
                    <!--                {{ item.introduce}}-->

                    <!--                    <p>入库日期：{{ item.dateOfManufacture }}</p>-->
                    <!--                    <p>库存：{{ item.count }} 个</p>-->
                    <!--                    <p>单价：{{ item.price.toFixed(2) }} 元</p>-->
                    <!--                    <p>总价值：{{ (item.count * item.price).toFixed(2) }} 元</p>-->
                </a-list-item>
                <a-pagination
                    style="margin-top: 10px;text-align: right"
                    show-size-changer
                    :default-current="1"
                    :total="list.result.total"
                    :pageSize="pageSize"
                    v-model="current"
                    @showSizeChange="onShowSizeChange"
                />
            </a-list>
        </a-spin>


        <a-drawer
            title="标签详情"
            placement="right"
            :closable="true"
            :visible="visible"
            width="400"
            @close="visible=false"
        >
            <a-button icon="edit" style="margin-right: 10px" @click="childrenDrawer=true">
                修改
            </a-button>
            <a-button icon="folder-open" type="primary" style="margin-right: 10px"
                      @click="$router.push(`/1/10/tag/${display.id}`)">
                相关的配件
            </a-button>
            <a-button type="danger" icon="delete" @click="deleteDialog = true">
                删除
            </a-button>
            <!--            <a-button type="danger" icon="delete" @click="deleteDialog = true">-->
            <!--                删除-->
            <!--            </a-button>-->
            <!--            {{ display }}-->

            <!--            <div style="text-align: center">-->
            <!--                <img-->
            <!--                    width="200"-->
            <!--                    alt="logo"-->
            <!--                    :src="display.imageUrl"-->
            <!--                    style="margin: 10px"-->
            <!--                />-->
            <!--            </div>-->


            <div style="margin-top: 20px">
                <p>id：{{ display.id }}</p>
                <p>名称：
                    <a-tag color="red">{{ display.name }}</a-tag>
                </p>
                <p>介绍：{{ display.introduce }}</p>
            </div>

            <a-drawer
                title="修改标签信息"
                width="400"
                :closable="true"
                :visible="childrenDrawer"
                @close="childrenDrawer = false"
            >
                <a-button icon="close" @click="childrenDrawer = false">
                    关闭
                </a-button>

                <a-button style="margin-left: 10px" icon="check" key="submit" type="danger" :loading="ok"
                          @click="modify">
                    提交
                </a-button>
                <!--                {{childrenDrawer + "-&#45;&#45;"}}-->


                <a-form-item style="margin-top: 10px" label="名称：">
                    <a-input v-model="submitData.name"/>
                </a-form-item>
                <a-form-item label="介绍：">
                    <a-input v-model="submitData.introduce"/>
                </a-form-item>
                <!--                <a-form-item label="数量：">-->
                <!--                    <a-input v-model="submitData.count"/>-->
                <!--                </a-form-item>-->
                <!--                <a-form-item label="单价：">-->
                <!--                    <a-input v-model="submitData.price"/>-->
                <!--                </a-form-item>-->
                <!--                <a-form-item label="所属标签：">-->
                <!--                    <a-input v-model="submitData.warehouseId"/>-->
                <!--                </a-form-item>-->

                <!--                <a-form>-->
                <!--                    <a-form-item label="生产日期：(年-月-日的格式)">-->
                <!--                        <a-input v-model="submitData.dateOfManufacture"/>-->
                <!--                    </a-form-item>-->
                <!--                </a-form>-->
            </a-drawer>
        </a-drawer>

        <a-modal v-model="deleteDialog" title="提示">
            <p> 您确定要删除<a-tag color="red">{{ display.name }}</a-tag>？</p>
            <p>该操作不可逆。</p>
            <a-button slot="footer" @click="deleteDialog = false">
                取消
            </a-button>
            <a-button key="submit" slot="footer" type="danger" :loading="ok" @click="handleOk">
                确定
            </a-button>
        </a-modal>

        <!--                {{ list }}-->
    </div>
</template>

<script>


export default {
    name: 'Home',
    components: {},
    data() {
        return {
            list: null,
            current: 1,
            pageSize: 10,
            spinning: true,
            visible: false,
            display: {
                "id": 1,
                "name": "默认标签",
                "introduce": "这是一个默认的标签",
                "imageUrl": "https://api.daidr.me/apis/imgholder/800x800.jpg?bg=F56C6C&fg=ffffff&text=%E4%BB%93%E5%BA%93"
            },
            deleteDialog: false,
            ok: false,
            childrenDrawer: false,
            date: null,
            msg: null,
            warehouse: {
                "code": 200,
                "status": "SUCCEED",
                "result": 0
            },
            submitData: {
                "code": 200,
                "status": "SUCCEED",
                "result": {
                    "id": null,
                    "name": "第八个标签",
                    "introduce": "66666",
                    "imageUrl": "https://api.daidr.me/apis/imgholder/800x800.jpg?bg=409EFF&fg=ffffff&text=第八个标签"
                }
            }
        }
    },
    created() {

        if (this.$route.params.current !== undefined && this.$route.params.current !== null && this.$route.params.pageSize !== undefined && this.$route.params.pageSize !== null) {
            this.current = Number.parseInt(this.$route.params.current);
            this.pageSize = Number.parseInt(this.$route.params.pageSize);
            // this.current = this.$route.params.current;
            // this.pageSize = this.$route.params.pageSize;
        } else {
            this.$router.push("/tag/1/10");
        }
        this.load();
        // console.log(this.axios)
        // this.axios.get("/part/1/1").then(res => this.list = res.data);
        // console.log(this.list)
    }
    ,
    methods: {
        onShowSizeChange(current, pageSize) {
            this.pageSize = pageSize;
            this.load();
        }
        ,
        load() {
            this.spinning = true;
            this.$router.push({
                params: {
                    current: this.current.toString(),
                    pageSize: this.pageSize.toString()
                    // current: this.current,
                    // pageSize: this.pageSize
                }
            });
            this.axios.get(`/tag/${this.current}/${this.pageSize}`).then(res => {
                this.list = res.data
                if (this.list.result.pages < Number.parseInt(this.current) && this.list.result.total !== 0) {
                    this.$router.push(`/tag/${this.list.result.pages}/${this.pageSize}`);
                    this.$router.go(0);
                } else {
                    setTimeout(() => this.spinning = false, 955);
                }
            });
        }
        ,
        loadPart() {
        }
        ,
        handleOk() {
            this.ok = true;
            this.axios.post(`/tag/${this.display.id}`).then(res => {
                let result = res.data;
                if (result.code === 400) {
                    setTimeout(() => {
                        this.$message.error(result.result);
                        this.ok = false;
                        this.deleteDialog = false;
                        this.childrenDrawer = false;
                        setTimeout(() => this.$router.go(0), 955);
                    }, 955);
                } else {
                    setTimeout(() => {
                        this.$message.success(result.result);
                        this.ok = false;
                        this.deleteDialog = false;
                        setTimeout(() => this.$router.go(0), 955);
                    }, 955);
                }
            })
        },
        modify() {
            if (this.submitData.name.length === 0) {
                this.$message.error("标签名称不能为空")
            } else if (this.submitData.name.length > 50) {
                this.$message.error("标签的名称长度不能大于50")
            } else if (this.submitData.introduce.length === 0) {
                this.$message.error("标签的介绍不能为空")
            } else if (this.submitData.introduce.length > 200) {
                this.$message.error("标签的介绍的字数不能大于200个")
            } else {
                this.ok = true;
                this.axios.post("/tag/update", this.submitData)
                    .then(res => {
                        let result = res.data;
                        if (result.code === 400) {
                            setTimeout(() => {
                                this.$message.error(result.result);
                                this.ok = false;
                            }, 955);
                        } else {
                            setTimeout(() => {
                                this.$message.success("修改成功");
                                this.ok = false;
                                setTimeout(() => this.$router.go(0), 955);
                            }, 955);
                        }
                    })
            }
        },
        detail(item) {
            this.display = item;
            this.submitData = JSON.parse(JSON.stringify(item));
            this.visible = true;
            this.axios.get(`/warehouse/count/${this.display.id}`).then(res => this.warehouse = res.data);
        }
    },
    watch: {
        current() {
            this.load();
        },
        // date(_new, _old) {
        //     // this.display.dateOfManufacture = this.date.getYear() + "-" + this.date.getMonth() + this.date.getDay();
        //     console.log(_new.getYear(),"---", _old);
        // }
    },
}
</script>
<style scoped>
.hold {
    line-height: 10;
}
</style>