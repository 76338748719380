<template>
    <a-layout id="components-layout-demo-side" style="min-height: 100vh; height: 100%">

        <a-layout-sider theme="light" v-if="!isMobile" v-model="collapsed">
            <Menu/>
        </a-layout-sider>
        <a-drawer
            v-else
            :placement="'left'"
            :visible="!collapsed"
            :closable="false"
            @close="putAway"
            :body-style="{padding: '0px'}"
        >
            <p style="text-align: center; font-size: 20px; padding-top: 20px">欢迎</p>
            <Menu/>
        </a-drawer>


        <a-drawer
            title="创建"
            placement="right"
            width="400"
            :closable="true"
            :visible="visible"
            @close="visible = false"
        >

            <div style="text-align: center">
                <a-radio-group @change="tabSelect" v-model="tab" default-value="b" button-style="solid"
                               style="margin: auto">
                    <a-radio-button value="warehouse">
                        仓库
                    </a-radio-button>
                    <a-radio-button value="part">
                        配件
                    </a-radio-button>
                    <a-radio-button value="tag">
                        标签
                    </a-radio-button>
                </a-radio-group>
                <br>

<!--                {{imageUrl}}-->
                <div style="text-align: left;margin-top: 10px" v-if="tab === 'warehouse'">
                    <a-form-item label="名称：">
                        <a-input placeholder="名称(小于50个字符)" v-model="warehouse.name"/>
                    </a-form-item>
                    <a-form-item label="图片：">
                        <div>
                            <a-upload
                                name="avatar"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                :name="'image'"
                                :headers="headers"
                                action="https://image.integer.top/api/upload"
                                :before-upload="beforeUpload"
                                @change="handleChange"
                            >
                                <img v-if="imageInfo"  width="100%" :src="imageInfo" alt="avatar"/>
                                <div v-else>
                                    <a-icon :type="loadingImage ? 'loading' : 'plus'"/>
                                    <div class="ant-upload-text">
                                        上传图片
                                    </div>
                                </div>
                            </a-upload>
                        </div>
                    </a-form-item>
                    <a-form-item label="图片链接：">
                        <a-input :disabled="true" placeholder="图片链接(小于200个字符)" v-model="warehouse.imageUrl"/>
                    </a-form-item>
                    <a-form-item label="介绍：">
                        <a-input placeholder="介绍(小于200个字符)" v-model="warehouse.introduce"/>
                    </a-form-item>
                </div>
                <div style="text-align: left;margin-top: 10px" v-if="tab === 'tag'">
                    <a-form-item label="标签名称：">
                        <a-input placeholder="标签名称(小于50个字符)" v-model="tag.name"/>
                    </a-form-item>
                    <a-form-item label="介绍：">
                        <a-input placeholder="标签介绍(小于200个字符)" v-model="tag.introduce"/>
                    </a-form-item>
                </div>

                <div style="text-align: left;margin-top: 10px" v-if="tab === 'part'">
                    <a-form-item label="名称：">
                        <a-input placeholder="名称(小于50个字符)" v-model="part.name"/>
                    </a-form-item>
                    <a-form-item label="图片：">
                        <div>
                            <a-upload
                                name="avatar"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                :name="'image'"
                                :headers="headers"
                                action="https://image.integer.top/api/upload"
                                :before-upload="beforeUpload"
                                @change="handleChange"
                            >
                                <img v-if="imageInfo"  width="100%" :src="imageInfo" alt="avatar"/>
                                <div v-else>
                                    <a-icon :type="loadingImage ? 'loading' : 'plus'"/>
                                    <div class="ant-upload-text">
                                        上传图片
                                    </div>
                                </div>
                            </a-upload>
                        </div>
                    </a-form-item>
                    <a-form-item label="图片链接：">
                        <a-input :disabled="true" placeholder="图片链接(小于200个字符)" v-model="part.imageUrl"/>
                    </a-form-item>
                    <a-form-item label="介绍：">
                        <a-input placeholder="介绍(小于200个字符)" v-model="part.introduce"/>
                    </a-form-item>
                    <a-form-item label="数量：">
                        <a-input placeholder="数量(整数，大于0，小于2147483647)" v-model="part.count"/>
                    </a-form-item>
                    <a-form-item label="单价：">
                        <a-input placeholder="单价(需大于0)" v-model="part.price"/>
                    </a-form-item>
                    <a-form-item label="所属仓库：">
                        <a-select v-model="part.warehouseId"
                                  style="width: 200px"
                                  @change="warehouseChange">
                            <a-select-option
                                v-for="i in warehouseList"
                                :key="i.id">
                                {{ i.name + "-id:" + i.id }}
                            </a-select-option>
                        </a-select>
                        <!--                    <a-input v-model="part.warehouseId"/>-->
                    </a-form-item>
                    <!--                    <a-form-item label="图片链接：">-->
                    <!--                        <a-input v-model="part.imageUrl"/>-->
                    <!--                    </a-form-item>-->

<!--                    <a-form>-->
<!--                        <a-form-item label="日期：(年-月-日的格式)">-->
<!--                            <a-input placeholder="按照年-月-日格式填写，例如2022-12-12" v-model="part.dateOfManufacture"/>-->
<!--                        </a-form-item>-->
<!--                    </a-form>-->
                    <a-form>
                        <a-form-item label="标签">
                            <a-select
                                mode="multiple"
                                placeholder="选择标签"
                                v-model="selectedList"
                                @change="tagChange"
                            >
                                <a-select-option v-for="i in allTags" :key="i.id">
                                    {{ i.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-form>
                </div>

                <div style="margin: 10px;text-align: left">
                    <a-button icon="close" @click="visible = false">
                        关闭
                    </a-button>

                    <a-button style="margin-left: 10px" icon="check" key="submit" type="primary" :loading="ok"
                              @click="submit">
                        提交
                    </a-button>
                </div>
            </div>
        </a-drawer>


        <a-layout>


            <a-layout-header style="background: #fff; padding: 0; line-height: 0">
                <div class="headers">
                    <template>
                        <a-page-header
                            title="电脑配件库存管理系统"
                            @back="() => this.collapsed = !this.collapsed"
                        >
                            <template v-if="isMobile" slot="tags">
                                <a-tag color="blue" @click="visible = true;imageInfo = null">
                                    添加
                                </a-tag>
                            </template>
                            <a-icon slot="backIcon" type="menu-fold"/>
                            <template slot="extra" v-if="!this.isMobile" class="plus">
                                <a-button class="plus" @click="visible = true;imageInfo = null" type="primary" shape="round" icon="plus"
                                          :size="'default'"/>
                            </template>
                        </a-page-header>
                    </template>

                </div>
            </a-layout-header>
            <a-layout-content style="margin: 0 16px">
                <a-breadcrumb style="margin: 16px 0">
                    <a-breadcrumb-item>电脑配件库存管理系统</a-breadcrumb-item>
                    <a-breadcrumb-item>{{ title }}</a-breadcrumb-item>
                </a-breadcrumb>
                <div :style="{ padding: '24px', background: '#fff', minHeight: '90%' }"
                     style="overflow-y: auto;height: 1px">

                    <router-view v-wechat-title='$route.meta.title'/>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import Menu from "@/components/Menu";
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
export default {
    data() {
        return {
            collapsed: localStorage.collapsed === undefined ? false : localStorage.collapsed === 'true',
            title: "",
            visible: false,
            tab: "part",
            warehouseList: [
                {
                    "id": 123455,
                    "name": "仓库",
                    "introduce": "介绍",
                    "imageUrl": "url"
                }
            ],
            warehouse: {
                "id": null,
                "name": "",
                "introduce": "这是仓库介绍",
                "imageUrl": "https://api.daidr.me/apis/imgholder/800x800.jpg?bg=F56C6C&fg=ffffff&text=%E4%BB%93%E5%BA%93"
            }
            ,
            part: {
                "id": null,
                "warehouseId": 1,
                "name": "",
                "count": 0,
                "introduce": "介绍",
                "price": 0.0,
                "dateOfManufacture": "",
                "imageUrl": "https://api.daidr.me/apis/imgholder/800x800.jpg?bg=409EFF&fg=ffffff&text=机箱",
                tags: []
            },
            ok: false,
            tag: {
                "id": null,
                "name": "",
                "introduce": ""
            },
            allTags: [
                {
                    "id": 1,
                    "name": "USB",
                    "introduce": "这是USB的一个标签"
                }
            ],
            selectedList: [],
            loadingImage: false,
            imageUrl: null,
            headers: {
                token: '54d9250182bff3f45709132d86795dc4',
            },
            imageInfo: null
        };
    },
    created() {
        this.title = this.menu.get(this.$route.meta.path).title;
        this.axios.get("/warehouse/1/2147483647")
            .then(res => this.warehouseList = res.data.result.list);
        this.axios.get("/tag/1/2147483647")
            .then(res => this.allTags = res.data.result.list);
    },
    methods: {
        putAway() {
            this.collapsed = !this.collapsed;
            localStorage.collapsed = this.collapsed;
        },
        tabSelect(v) {
            // console.log(v);
            // console.log(this.tab)
        },
        warehouseChange(v) {
            // // console.log(v)
            this.part.warehouseId = v;
            // console.log(this.part)
        },
        submit() {
            if (this.tab === 'part') {
                if (this.part.name.length === 0) {
                    this.$message.error("配件名称不能为空")
                } else if (this.part.name.length > 50) {
                    this.$message.error("配件名称长度不能大于50");
                } else if (this.part.introduce.length === 0) {
                    this.$message.error("配件的介绍不能为空")
                } else if (this.part.introduce.length > 200) {
                    this.$message.error("配件的介绍不能大于200")
                } else if (this.part.count.length === 0) {
                    this.$message.error("配件的数量不能为空")
                } else if (Number.parseInt(this.part.count) < 0) {
                    this.$message.error("配件的数量不能小于0")
                } else if (isNaN(Number.parseInt(this.part.count))) {
                    this.$message.error("请输入正确的数据")
                } else if (Number.parseInt(this.part.count) > 2147483647) {
                    this.$message.error("配件的数量不能大于2147483647")
                } else if (this.part.price.length === 0) {
                    this.$message.error("配件的价格不能为空")
                } else if (Number.parseFloat(this.part.price) < 0) {
                    this.$message.error("配件的价格不能小于0")
                } else if (this.part.warehouseId.length === 0) {
                    this.$message.error("配件的仓库不能为空")
                } else if (this.part.imageUrl.length === 0) {
                    this.$message.error("配件的图片链接不能为空")
                } else {
                    this.ok = true;
                    this.axios.post("/part/insert", this.part)
                        .then(res => {
                            let result = res.data;
                            if (result.code === 400) {
                                setTimeout(() => {
                                    this.$message.error(result.result);
                                    this.ok = false;
                                }, 1500);
                            } else {
                                setTimeout(() => {
                                    this.$message.success("添加成功");
                                    this.ok = false;
                                    setTimeout(() => this.$router.go(0), 1500);
                                }, 1500);
                            }
                        })
                }
            } else if (this.tab === 'tag') {
                if (this.tag.name.length === 0) {
                    this.$message.error("标签名称不能为空")
                } else if (this.tag.name.length > 50) {
                    this.$message.error("标签的名称长度不能大于50")
                } else if (this.tag.introduce.length === 0) {
                    this.$message.error("标签的介绍不能为空")
                } else if (this.tag.introduce.length > 200) {
                    this.$message.error("标签的介绍的字数不能大于200个")
                } else {
                    this.ok = true;
                    this.axios.post("/tag/insert", this.tag)
                        .then(res => {
                            let result = res.data;
                            if (result.code === 400) {
                                setTimeout(() => {
                                    this.$message.error(result.result);
                                    this.ok = false;
                                }, 1500);
                            } else {
                                setTimeout(() => {
                                    this.$message.success("添加成功");
                                    this.ok = false;
                                    setTimeout(() => this.$router.go(0), 1500);
                                }, 1500);
                            }
                        })
                }
            } else if (this.tab === 'warehouse') {
                if (this.warehouse.name.length === 0) {
                    this.$message.error("仓库名称不能为空")
                } else if (this.warehouse.name.length > 50) {
                    this.$message.error("仓库的名称长度不能大于50")
                } else if (this.warehouse.introduce.length === 0) {
                    this.$message.error("仓库的介绍不能为空")
                } else if (this.warehouse.introduce.length > 200) {
                    this.$message.error("仓库的介绍的字数不能大于200个")
                } else if (this.warehouse.imageUrl.length === 0) {
                    this.$message.error("仓库的图片链接不能为空")
                } else {
                    this.ok = true;
                    this.axios.post("/warehouse/insert", this.warehouse)
                        .then(res => {
                            let result = res.data;
                            if (result.code === 400) {
                                setTimeout(() => {
                                    this.$message.error(result.result);
                                    this.ok = false;
                                }, 1500);
                            } else {
                                setTimeout(() => {
                                    this.$message.success("添加成功");
                                    this.ok = false;
                                    setTimeout(() => this.$router.go(0), 1500);
                                }, 1500);
                            }
                        })
                }
            }
        },
        tagChange(v) {
            // // console.log(v);
            this.part.tags = v;
        },
        handleChange(info) {
            if (info.file.status === 'uploading') {
                this.loading = true;
                return;
            }
            if (info.file.status === 'done') {
                // Get this url from response in real world.

                // // console.log(info.file.response);
                this.part.imageUrl = this.warehouse.imageUrl = this.imageUrl = info.file.response.data.url;

                getBase64(info.file.originFileObj, imageUrl => {
                    setTimeout(() => {
                        this.imageInfo = imageUrl;
                        this.loading = false;
                        this.$message.success("上传成功")
                    }, 1500)
                });
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('只能上传JPG或者PNG后缀的图片');
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('图片必须小于2MB');
            }
            return isJpgOrPng && isLt2M;
        }
    },
    components: {
        Menu
    },
    watch: {
        'part.name': {
            handler(_new, _old) {
                if (this.imageInfo === null) {
                    this.part.imageUrl = `https://api.daidr.me/apis/imgholder/800x800.jpg?bg=409EFF&fg=ffffff&text=${_new}`;
                }
                // // console.log(this.part.imageUrl);
            }
        },
        'warehouse.name': {
            handler(_new, _old) {
                if (this.imageInfo === null) {
                    this.warehouse.imageUrl = `https://api.daidr.me/apis/imgholder/800x800.jpg?bg=409EFF&fg=ffffff&text=${_new}`;
                }
                // // console.log(this.part.imageUrl);
            }
        }
    }

}
</script>

<style>
#components-layout-demo-side .logo {
    height: 32px;
    /*background: rgba(183, 152, 152, 0.2);*/
    color: #000000;
    text-align: center;
    margin: 16px;
}

.search, .msearch {
    width: 200px;
    display: inline-block;
    float: right;
    margin: 15px 30px 30px;
}

.msearch {
    width: 160px;
}

.headers {
    /*display: inline-block;*/
}

.title {
    font-size: 23px;
    margin-left: 10px;
    font-weight: bold;
    color: black;
    margin-top: 20px;
}

</style>
