import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Axios from "axios";
import axios from "axios";
import VueWechatTitle from 'vue-wechat-title'
import {menu} from "@/menu";
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.use(Antd);
Axios.defaults.baseURL = "https://api.pc.integer.top/api/"
Vue.prototype.axios = Axios;
axios.defaults.headers.common['token'] = '54d9250182bff3f45709132d86795dc4'
Vue.prototype.$axios = axios
Vue.use(VueWechatTitle);
Vue.prototype.menu = menu;
Vue.prototype.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) !== null;


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
